<template>
  <vx-card class="p-4">
    <form action="">
      <!-- <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">Username:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <vs-input v-validate="'required'" class="w-full" name="username" v-model="user.username" placeholder="4657867897" />
          <span class="text-danger text-sm" v-show="errors.has('username')">{{ errors.first('username') }}</span>
        </div>
      </div> -->
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('fields.name') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <div class="vx-col my-2 w-full">
            <vs-input v-validate="'required'" class="w-full" name="name_tm" placeholder="Turkmen" v-model="user.name" />
            <span class="text-danger text-sm" v-show="errors.has('name_tm')">{{ errors.first('name_tm') }}</span>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('fields.phone') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <div class="vx-col my-2 w-full">
            <vs-input v-validate="'required'" class="w-full" name="phone" placeholder="Turkmen" v-model="user.phone" />
            <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('fields.email') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <vs-input v-validate="'required_if:isActive,true'" name="email" class="w-full" v-model="user.email" />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>
      </div>
      <!-- <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">Regions:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <v-select multiple v-validate="'required'" :options="filteredRegions" :label="`name_${$i18n.locale}`" name="regions" placeholder="Select regions" v-model="user.carrier_regions" />
          <span class="text-danger text-sm" v-show="errors.has('regions')">{{ errors.first('regions') }}</span>
        </div>
      </div> -->
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('supplier') }}?</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <li class="vx-col my-2 ml-2 mr-4 flex">
            <vs-switch name="isBulk" v-model="user.isSupplier" />
            <label class="ml-3">Supplier user?</label>
          </li>
        </div>
      </div>
      <div v-if="user.isSupplier" class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('props.company_name') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <div class="vx-col my-2 w-full">
            <vs-input v-validate="'required'" class="w-full" name="company" placeholder="Company" v-model="user.company_name" />
            <span class="text-danger text-sm" v-show="errors.has('company')">{{ errors.first('company') }}</span>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('isBlocked') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <li class="vx-col my-2 ml-2 mr-4 flex">
            <vs-switch name="isBulk" v-model="user.isBlocked" />
            <label class="ml-3">{{ $t('block') }}?</label>
          </li>
        </div>
      </div>
      <vs-row>
        <vs-button type="filled" @click.prevent="submitForm" class="mr-auto">{{ $t('submit') }}</vs-button>
        <vs-button type="border" color="warning" @click.prevent="$router.back()" class="ml-4 ml-auto block">{{ $t('cancel') }}</vs-button>
      </vs-row>
    </form>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      user: {}
    }
  },
  created () {
    this.fetchUser()
  },
  methods: {
    async fetchUser () {
      await this.$http.get(`/users/${this.$route.params.id}`).then(response => {
        if (response.status === 200) { this.user = response.data }
      })
    },
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/users/edit/${this.user.uuid}`, {
            ...this.user
          }).then(response => {
            if (response.status === 200) {
              this.notify('Success', 'Courier data successfully updated!', 'icon-check-circle', 'primary')
              this.$router.back()
            }
          }).catch(err => { this.notify('Error', err.message, 'icon-alert-circle', 'danger') })
        } else { this.notify('Error', 'Form is not valid. Check and try again.', 'icon-alert-circle', 'danger') }
      })
    },
    notify (title, text, icon, color) {
      this.$vs.notify({ title, text, iconPack: 'feather', icon, color })
    }
  }
}
</script>
